import { type QueryDeep, type QueryFields } from '@directus/sdk'
import type { Collections, Schema } from '~/.directus/generated/client'
import { PEOPLE_STATUS, STATUS } from '~/types'

// Data fetching within the Departments collection
export const useDepartmentsData = async () => {
  const { $directus, $readItems } = useNuxtApp()
  const { localeProperties } = useI18n()
  const langIso = localeProperties.value.iso || ''

  return useAsyncData(
    `${langIso}/departments`,
    () =>
      $directus.request(
        $readItems('departments', {
          fields: [
            'id',
            'status',
            'sort',
            'color_main',
            'email',
            {
              translations: [
                'id',
                'title',
                'languages_code',
                'short_description',
                'slug',
              ],
            },
          ],
          filter: {
            status: { _eq: STATUS.PUBLISHED },
          },
        })
      ),
    {
      // Store current translation
      transform: (departments) =>
        departments.map((department) => ({
          ...department,
          currentTranslation: department.translations?.find(
            ({ languages_code }) => languages_code === langIso
          ),
        })),
    }
  )
}

// Data fetching within the Departments collection for a specific slug
export const useDepartmentData = async (slug: string) => {
  const { $directus, $readItems } = useNuxtApp()
  const { localeProperties } = useI18n()
  const langIso = localeProperties.value.iso || ''

  return useAsyncData(
    `${langIso}/departments/${slug}`,
    () =>
      $directus.request(
        $readItems('departments', {
          fields,
          limit: 1,
          // Filter departments
          filter: {
            status: { _eq: STATUS.PUBLISHED },
            translations: {
              languages_code: {
                _eq: langIso,
              },
              slug: {
                _eq: slug,
              },
            },
          },
          deep: deep(langIso),
        })
      ),
    {
      // Store current translation and flatten single-element array
      transform: (departments) =>
        departments.map((department) => ({
          ...department,
          currentTranslation: department.translations?.find(
            ({ languages_code }) => languages_code === langIso
          ),
          currentSeo: (department?.SEO as Collections.SEO)?.translations?.find(
            ({ languages_code }) => languages_code === langIso
          ),
        }))?.[0] ?? null,
    }
  )
}
// TypeScript Generics https://www.typescriptlang.org/docs/handbook/2/generics.html
const fields: QueryFields<Schema, Collections.Departments> = [
  'id',
  'status',
  'sort',
  'color_main',
  'title',
  {
    SEO: [
      {
        translations: [
          'title',
          'description',
          'languages_code',
          {
            og_image: ['id'],
          },
          {
            tw_image: ['id'],
          },
        ],
      },
    ],
  },
  {
    people: [
      '*',
      {
        people_id: [
          '*',
          {
            profile_img: ['*'],
          },
        ],
      },
    ],
  },
  {
    translations: [
      'id',
      'title',
      'slug',
      'languages_code',
      'short_description',
      'stack_active',
      'stack_title',
      'stack_text',
    ],
    stack_logos: ['id', 'directus_files_id'],
    skillsets: [
      'id',
      {
        skillsets_id: [
          'status',
          'name',
          {
            translations: ['id', 'languages_code', 'title', 'description'],
            skills: [
              'id',
              {
                skills_id: [
                  'status',
                  'name',
                  {
                    translations: ['id', 'languages_code', 'name'],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
    case_studies: [
      'id',
      {
        case_studies_id: [
          'id',
          'title',
          {
            translations: [
              'id',
              'languages_code',
              'title',
              'slug',
              'short_description',
              'description',
            ],
            skills: [
              'id',
              {
                skills_id: [
                  'id',
                  'name',
                  'logo',
                  {
                    translations: ['id', 'languages_code'],
                  },
                ],
              },
            ],
            ctas: [
              'id',
              {
                CTAs_id: [
                  'id',
                  'type',
                  {
                    translations: ['id', 'languages_code', 'text', 'title'],
                    internal_cta: [
                      {
                        item: [
                          'id',
                          'title',
                          {
                            translations: [
                              'id',
                              'title',
                              'slug',
                              'languages_code',
                            ],
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
            media: ['id', 'directus_files_id'],
          },
        ],
      },
    ],
  },
]

const deep: (
  langIso: string,
  preview?: boolean
) => QueryDeep<Schema, Collections.Departments> = (langIso, preview) => ({
  people: {
    _filter: {
      people_id: {
        status: {
          _eq: PEOPLE_STATUS.PUBLIC,
        },
      },
    },
  },
  skillsets: {
    // Filter by skillset status
    ...(preview
      ? {}
      : {
          _filter: {
            skillsets_id: {
              status: { _eq: STATUS.PUBLISHED },
            },
          },
        }),
    // Filter skillset translations
    skillsets_id: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langIso,
          },
        },
      },
      // Filter skill translations
      skills: {
        skills_id: {
          translations: {
            _filter: {
              languages_code: {
                _eq: langIso,
              },
            },
          },
        },
      },
    },
  },
  case_studies: {
    _filter: {
      case_studies_id: {
        status: { _eq: STATUS.PUBLISHED },
      },
    },
    // Filter case study translations
    case_studies_id: {
      translations: {
        _filter: {
          languages_code: {
            _eq: langIso,
          },
        },
      },
      // Filter skill translations
      skills: {
        skills_id: {
          translations: {
            _filter: {
              languages_code: {
                _eq: langIso,
              },
            },
          },
        },
      },
      // Filter CTA translations
      ctas: {
        CTAs_id: {
          translations: {
            _filter: {
              languages_code: {
                _eq: langIso,
              },
            },
          },
          internal_cta: {
            'item:Pages': {
              translations: {
                _filter: {
                  languages_code: {
                    _eq: langIso,
                  },
                },
              },
            },
          },
        },
      },
    },
  },
})
