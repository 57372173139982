import type { Collections } from '~/.directus/generated/client'
import type { CTA } from '~/types/CTA'

export const resolveCTA = (cta: CTA) => {
  // External link
  if (cta.type === 'external') {
    return {
      href: `${(cta.currentTranslation as Collections.CTAsTranslations).url}`,
      target: '_blank',
      title: `${cta.currentTranslation.title}`,
    }

    // Internal link (to Pages collection)
  } else {
    const [{ item }] = cta.internal_cta
    if (item) {
      const [{ slug, title }] = (item as Collections.Pages).translations
      if (slug) {
        return {
          to: `/${slug}`,
          title: `${title}`,
        }
      }
    }
  }

  // Neither external nor internal link returned anything useful
  return {}
}
