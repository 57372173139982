export * from './Department'
export * from './Page'
export * from './CTA'
export * from './CaseStudy'
export * from './Menu'
export * from './Skill'

export enum STATUS {
  PUBLISHED = 'published',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}

export enum PEOPLE_STATUS {
  PUBLIC = 'public',
  DRAFT = 'draft',
  ARCHIVED = 'archived',
}
