import type { Collections, Types } from '~/.directus/generated/client'

export const transformFilesList = async (
  list:
    | Collections.ComponentsTranslationsFiles[]
    | Collections.ProjectsInfoFiles[]
    | Collections.DepartmentsFiles[]
    | Collections.CaseStudiesFiles[] = []
) => {
  return (
    await Promise.all(
      list.map(async (file) => {
        const transformedFile = await transformFile(file)
        return !!transformedFile ? [transformedFile] : []
      })
    )
  ).flat()
}

export const transformFile = async (
  file:
    | Collections.ComponentsTranslationsFiles
    | Collections.ProjectsInfoFiles
    | Collections.DepartmentsFiles
    | Collections.CaseStudiesFiles
) => {
  const runtimeConfig = useRuntimeConfig()

  const { data, status } = await useFile(file.directus_files_id as Types.UUID)
  if (status.value === 'error' || !data.value) {
    return null
  }

  return {
    ...file,
    mediaUrl: `${runtimeConfig.public.cmsUrl}assets/${file.directus_files_id}`,
    alt: data.value.title ?? undefined,
    metadata: data.value,
  }
}

export const getAbsoluteAssetsUrl = (id: string) => {
  const runtimeConfig = useRuntimeConfig()
  return `${runtimeConfig.public.cmsUrl}assets/${id}`
}
