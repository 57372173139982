<script setup lang="ts">
import { defineProps, computed } from 'vue'

interface Props {
  src: string
  provider?: 'ipx' | 'directus' | 'cloudflare'
  modifiers?: object
  alt?: string
  ratio?: string
  quality?: string
  sizes?: string
}
const props = withDefaults(defineProps<Props>(), {
  provider: 'cloudflare',
  sizes: '200px, sm:400px, lg:1200px',
})

// Aspect Ratios
const aspectRatioClass = computed(() => {
  if (props.ratio === '16/9') {
    return 'aspect-w-16 aspect-h-9'
  } else if (props.ratio === '9/16') {
    return 'aspect-w-9 aspect-h-16'
  } else if (props.ratio === '1/1') {
    return 'aspect-w-1 aspect-h-1'
  } else if (props.ratio === 'auto') {
    return 'ratio-auto'
  } else {
    return ''
  }
})

// Generate Ratio from asset dimension values
const aspectRatioStyle = computed(() => {
  if (props.ratio === 'auto') {
    return `1/1`
  } else {
    return ''
  }
})
</script>

<template>
  <div
    :style="{ '--aspect-ratio': aspectRatioStyle }"
    :class="aspectRatioClass"
  >
    <NuxtImg
      :modifiers="modifiers"
      :provider="provider"
      class="block w-full h-auto"
      :src="provider === 'cloudflare' ? getAbsoluteAssetsUrl(src) : src"
      :alt="alt"
      :width="'100%'"
      format="auto"
      loading="lazy"
      :quality="quality ?? '50'"
      :sizes="sizes"
      densities="x1 x2"
    />
  </div>
</template>
